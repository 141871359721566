// const apiUrl = '10.0.70.86:1312'

// const method = 'http://' // http:// or https:// or // (auto)
let apiUrl = 'http://10.0.70.86:1312'

switch (process.env.REACT_APP_ENV) {
  case 'dev':
    apiUrl = 'http://localhost:1530'
    break
  case 'test':
    apiUrl = 'https://api.alo88.xyz'
    break
  case 'prod':
  case 'production':
  case 'production ':
    apiUrl = 'https://apilive.alo88.bet'
    break
  default:
    apiUrl = 'http://localhost:1530'
}


export const API_URL = `${apiUrl}`

export const KEY_HEADER_THREE = 'sum'
